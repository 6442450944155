<template>
    <div :id='domId' class="modal" tabindex="-1" :data-bs-backdrop='getDataBSBackdrop()' :data-bs-keyboard='getDataBSKeyboard()'>
        <div :class="getModalDivClasses()">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button ref='closeButton' type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div v-if='!(noButtons==="true")' class="modal-footer">
                    <button :id = 'okButtonDomId' type="button" :class="getOkButtonClasses()" data-bs-dismiss="modal" @click='$emit("ok", "ok")'>{{ okTitle }}</button>
                    <button :id = 'cancelButtonDomId' v-if='!okOnly' type="button" :class="getCancelButtonClasses()" data-bs-dismiss="modal" @click='$emit("cancel", "cancel")'>{{ cancelTitle }}</button>
                </div>
            </div>
        </div>
    </div>
    <!-- hidden buttons to hide and show the modal when clicked -->
    <button ref='showHiddenButton' style='display: none' type="button" class="btn btn-primary" data-bs-toggle="modal" :data-bs-target='getDataBSTargetName()' />
</template>

<script>

import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'KBModal',
    emits: ['ok', 'cancel'],
    props: {
        title: {
            type: String,
            required: true
        },
        okTitle: {
            type: String,
            default: 'OK'
        },
        cancelTitle: {
            type: String,
            default: 'Cancel'
        },
        noCloseOnBackdrop: {
            type: Boolean,
            default: true
        },
        noCloseOnEsc: {
            type: Boolean,
            default: true
        },
        autoFocusButton: {
            type: String,
            default: 'ok'
        },
        okOnly: {
            type: Boolean,
            default: false
        },
        noButtons: {
            type: String,
            default: 'false'
        },
        size: { // one of sm, '', lg, xl  (note that '' is medium, which is the default)
            type: String,
            default: ''
        }
    },
    data() {
        return {
            // this is the data model for the form
            domId: null,
            isShown: false,
            okButtonDomId: null,
            cancelButtonDomId: null
        }
    },
    methods: {
        show: function() {
            // console.log('in KBModal show')
            this.$refs.showHiddenButton.click()
        },
        hide: function() {
            // console.log('in KBModal hide')
            this.$refs.closeButton.click()
        },
        getDataBSTargetName() {
            return '#' + this.domId
        },
        getDataBSBackdrop() {
            if (this.noCloseOnBackdrop) {
                return 'static'
            }
            else {
                return true
            }
        },
        getDataBSKeyboard() {
            return !this.noCloseOnEsc
        },
        getOkButtonClasses() {
            if (this.autoFocusButton === 'ok') {
                return 'btn btn-primary'
            }
            else {
                return 'btn btn-secondary'
            }
        },
        getCancelButtonClasses() {
            if (this.autoFocusButton === 'ok') {
                return 'btn btn-secondary'
            }
            else {
                return 'btn btn-primary'
            }
        },
        getModalDivClasses() {
            let c = 'modal-dialog modal-dialog-scrollable'
            if (this.size && this.size.length > 0) {
                c += ' modal-' + this.size
            }
            return c
        }
    },
    created() {
        let guid = uuidv4()
        this.domId = 'modal-' + guid
        this.okButtonDomId = 'okButton-' + guid
        this.cancelButtonDomId = 'cancelButton-' + guid
        // console.log('KBModal created, domIds: ' + this.domId + ', ' + this.okButtonDomId + ', ' + this.cancelButtonDomId)
    },
    mounted() {
        // console.log('in KBModal mounted, domId: ' + this.domId + ', title: ' + this.title)
        let inputToFocus = (this.autoFocusButton === 'cancel') ? document.getElementById(this.cancelButtonDomId) : document.getElementById(this.okButtonDomId)
        // console.log('input to focus for title: ' + this.title + ' input: ' + JSON.stringify(inputToFocus))
        let myModal = document.getElementById(this.domId)

        if (myModal) {
            myModal.addEventListener('shown.bs.modal', () => {
                // console.log('FOCUSING input to focus for title: ' + this.title + ' input: ' + JSON.stringify(inputToFocus))
                if (inputToFocus) {
                    inputToFocus.focus()
                }
            })
            // console.log('set shown.bs.modal event listener for modal with id ' + this.domId)
        }
        else {
            // console.log('modal for id ' + this.domId + ' not found')
        }
    }
}
</script>

<style>
</style>
