import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/Home'
import Login from '@/components/Login'
import store from '../store/index.js'

// below everything is lazy loaded except for Home and Login
const routes = [
    {
        path: '/',
        component: Home,
        name: 'Home'
    },
    {
        path: '/login',
        component: Login,
        name: 'Login'
    },
    {
        path: '/drafts',
        component: () => import(/* webpackChunkName: "drafts" */ '@/components/Drafts'),
        name: 'Drafts'
    },
    {
        path: '/documents',
        component: () => import(/* webpackChunkName: "documents" */ '@/components/Documents'),
        name: 'Docs'
    },
    {
        path: '/about',
        component: () => import(/* webpackChunkName: "about" */ '@/components/About'),
        name: 'About'
    },
    {
        path: '/help',
        component: () => import(/* webpackChunkName: "help" */ '@/components/Help'),
        name: 'Help'
    },
    {
        path: '/settings',
        component: () => import(/* webpackChunkName: "settings" */ '@/components/Settings'),
        name: 'Settings'
    },
    {
        path: '/ways-to-help',
        component: () => import(/* webpackChunkName: "waysToHelp" */ '@/components/WaysToHelp'),
        name: 'WaysToHelp'
    },
    {
        path: '/privacy-policy',
        component: () => import(/* webpackChunkName: "privacyPolicy" */ '@/components/PrivacyPolicy'),
        name: 'PrivacyPolicy'
    },
    {
        path: '/contact',
        component: () => import(/* webpackChunkName: "contact" */ '@/components/Contact'),
        name: 'Contact'
    },
    {
        path: '/account',
        component: () => import(/* webpackChunkName: "account" */ '@/components/Account'),
        name: 'Account'
    },
    {
        path: '/subscription',
        component: () => import(/* webpackChunkName: "subscription" */ '@/components/Subscription'),
        name: 'Subscription'
    },
    {
        path: '/draft-detail/:id/:title?',
        component: () => import(/* webpackChunkName: "draftDetail" */ '@/components/DraftDetail'),
        name: 'DraftDetail'
    },
    {
        path: '/draft-contribute',
        component: () => import(/* webpackChunkName: "draftUpload" */ '@/components/DraftUpload'),
        name: 'DraftUpload'
    },
    {
        path: '/collection-drafts/:searchType/:bookOrCategoryId?/:collectionTitle?',
        component: () => import(/* webpackChunkName: "draftSearch" */ '@/components/DraftSearch'),
        name: 'DraftSearch'
    },
    {
        path: '/draft-threading-search',
        component: () => import(/* webpackChunkName: "draftThreadingSearch" */ '@/components/DraftThreadingSearch'),
        name: 'DraftThreadingSearch'
    },
    {
        path: '/draft-tieup-search',
        component: () => import(/* webpackChunkName: "draftTieupSearch" */ '@/components/DraftTieupSearch'),
        name: 'DraftTieupSearch'
    },
    {
        path: '/document-search/:searchType/:id?/:name?',
        component: () => import(/* webpackChunkName: "documentSearch" */ '@/components/DocumentSearch'),
        name: 'DocumentSearch'
    },
    {
        path: '/document-detail/:id/:title?',
        component: () => import(/* webpackChunkName: "documentDetail" */ '@/components/DocumentDetail'),
        name: 'DocumentDetail'
    },
    {
        path: '/acknowledgements',
        component: () => import(/* webpackChunkName: "acknowledgements" */ '@/components/Acknowledgements'),
        name: 'Acknowledgements'
    },
    {
        path: '/weaving-software',
        component: () => import(/* webpackChunkName: "weavingSoftware" */ '@/components/WeavingSoftware'),
        name: 'WeavingSoftware'
    },
    {
        path: '/digital-downloads',
        component: () => import(/* webpackChunkName: "digitalDownloads" */ '@/components/DigitalDownloads'),
        name: 'DigitalDownloads'
    },
    {
        path: '/treadle-reducer',
        component: () => import(/* webpackChunkName: "treadleReducer" */ '@/components/TreadleReducer'),
        name: 'TreadleReducer'
    },
    {
        path: '/tag-cloud/:searchType',
        component: () => import(/* webpackChunkName: "tagCloud" */ '@/components/TagCloud'),
        name: 'TagCloud'
    },
    {
        path: '/tags-cleanup',
        component: () => import(/* webpackChunkName: "tagsCleanup" */ '@/components/TagsCleanup'),
        name: 'TagsCleanup'
    },
    {
        path: '/myboards',
        component: () => import(/* webpackChunkName: "myBoards" */ '@/components/MyBoards'),
        name: 'MyBoards'
    },
    {
        path: '/board-search',
        component: () => import(/* webpackChunkName: "boardSearch" */ '@/components/BoardSearch'),
        name: 'BoardSearch'
    },
    {
        path: '/updates',
        component: () => import(/* webpackChunkName: "updates" */ '@/components/Updates'),
        name: 'Updates'
    },
    {
        path: '/draft-editor',
        component: () => import(/* webpackChunkName: "draftEditor" */ '@/components/DraftEditor'),
        name: 'DraftEditor'
    },
    {
        path: '/draft-arranger',
        component: () => import(/* webpackChunkName: "draftArranger" */ '@/components/DraftArranger'),
        name: 'DraftArranger'
    },
    {
        path: '/draft-create',
        component: () => import(/* webpackChunkName: "draftCreate" */ '@/components/DraftCreate'),
        name: 'DraftCreate'
    },
    {
        path: '/draft-editor-instructions',
        component: () => import(/* webpackChunkName: "draftEditorInstructions" */ '@/components/DraftEditorInstructions'),
        name: 'DraftEditorInstructions'
    },
    {
        path: '/draft-editor-introduction',
        component: () => import(/* webpackChunkName: "draftEditorIntroduction" */ '@/components/DraftEditorIntroduction'),
        name: 'DraftEditorIntroduction'
    },
    {
        path: '/discover-gradients-promo',
        component: () => import(/* webpackChunkName: "discoverGradientsPromo" */ '@/components/DiscoverGradientsPromo'),
        name: 'DiscoverGradientsPromo'
    },
    {
        path: '/gorgeous-gradients-promo',
        component: () => import(/* webpackChunkName: "gorgeousGradientsPromo" */ '@/components/GorgeousGradientsPromo'),
        name: 'GorgeousGradientsPromo'
    },
    {
        path: '/stash-weaving-promo',
        component: () => import(/* webpackChunkName: "stashWeavingSuccessPromo" */ '@/components/StashWeavingSuccessPromo'),
        name: 'StashWeavingSuccessPromo'
    },
    {
        path: '/drafting',
        component: () => import(/* webpackChunkName: "stashWeavingSuccessPromo" */ '@/components/HandwovenDraftingPromo'),
        name: 'HandwovenDraftingPromo'
    },
    {
        path: '/dull-to-brilliant-promo',
        component: () => import(/* webpackChunkName: "dullToBrilliantPromo" */ '@/components/DullToBrilliantPromo'),
        name: 'DullToBrilliantPromo'
    },
    {
        path: '/holidays-2023-promo',
        component: () => import(/* webpackChunkName: "dullToBrilliantPromo" */ '@/components/Holidays2023Promo'),
        name: 'Holidays2023Promo'
    },
    {
        path: '/handwoven-promo',
        component: () => import(/* webpackChunkName: "handwovenPromo" */ '@/components/HandwovenPromo'),
        name: 'HandwovenPromo'
    },
    {
        path: '/handwoven-draft-editor-promo',
        component: () => import(/* webpackChunkName: "handwovenPromo" */ '@/components/HandwovenDraftEditorPromo'),
        name: 'HandwovenDraftEditorPromo'
    },
    {
        path: '/warp-weave-draft-editor-promo',
        component: () => import(/* webpackChunkName: "handwovenPromo" */ '@/components/WarpWeaveDraftEditorPromo'),
        name: 'WarpWeaveDraftEditorPromo'
    },
    {
        path: '/weave-along-promo',
        component: () => import(/* webpackChunkName: "weaveAlongPromo" */ '@/components/WeaveAlongPromo'),
        name: 'WeaveAlongPromo'
    },
    {
        path: '/academy-promo',
        component: () => import(/* webpackChunkName: "academyPromo" */ '@/components/AcademyPromo2023'),
        name: 'AcademyPromo2023'
    },
    {
        path: '/all-access-promo',
        component: () => import(/* webpackChunkName: "allAccessPromo" */ '@/components/AllAccessPromo'),
        name: 'AllAccessPromo'
    },
    {
        path: '/long-thread-promo',
        component: () => import(/* webpackChunkName: "longThreadPromo" */ '@/components/LongThreadPromo'),
        name: 'LongThreadPromo'
    },
    {
        path: '/handwoven-2024-drafts-promo',
        component: () => import(/* webpackChunkName: "longThread2023Promo" */ '@/components/LongThreadDec2023Promo'),
        name: 'LongThreadDec2023Promo'
    },
    {
        path: '/warpweave-promo',
        component: () => import(/* webpackChunkName: "warpWeavePromo" */ '@/components/WarpWeavePromo'),
        name: 'WarpWeavePromo'
    },
    {
        path: '/warpweave-promo-2021',
        component: () => import(/* webpackChunkName: "warpWeavePromo2021" */ '@/components/WarpWeavePromo-2021'),
        name: 'WarpWeavePromo2021'
    },
    {
        path: '/mycs-promo',
        component: () => import(/* webpackChunkName: "mycsPromo" */ '@/components/MYCSPromo'),
        name: 'MYCSPromo'
    },
    {
        path: '/draft-view-test/:id',
        component: () => import(/* webpackChunkName: "draftViewTest" */ '@/components/DraftViewTest'),
        name: 'DraftViewTest'
    },
    {
        path: '/draft-repeats',
        component: () => import(/* webpackChunkName: "draftRepeats" */ '@/components/DraftRepeats'),
        name: 'DraftRepeats'
    },
    {
        path: '/profile',
        component: () => import(/* webpackChunkName: "profile" */ '@/components/Profile'),
        name: 'Profile'
    },
    {
        path: '/amalgamation',
        component: () => import(/* webpackChunkName: "amalgamation" */ '@/components/Amalgamation'),
        name: 'Amalgamation'
    },
    {
        path: '/account-reset/:code',
        component: () => import(/* webpackChunkName: "passwordReset" */ '@/components/PasswordReset'),
        name: 'PasswordReset'
    }
    // {
    //     path: '/about',
    //     name: 'about',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        let delay = to && to.name && to.name === 'DraftSearch' ? 1000 : 500
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // console.log('to: ' + JSON.stringify(to) + ', from: ' + JSON.stringify(from) + ' Saved Position: ' + JSON.stringify(savedPosition))
                if (!savedPosition && to && from && to.fullPath !== from.fullPath) {
                    resolve({ left: 0, top: 0, x: 0, y: 0, behavior: 'smooth' })
                }
                else {
                    if (savedPosition) {
                        resolve({ left: savedPosition.left, top: savedPosition.top, x: savedPosition.left, y: savedPosition.top, behavior: 'smooth' })
                    }
                }
            }, delay)
            // note: 1 second delay is so content can full re-render before scroll position is applied, otherwise pages like
            // draft search results with lots of icons might scroll to a position that doesn't yet exist
        })
    }
})

router.beforeEach(async (to, from) => {
    // console.log('in routerInstance.beforeEach, to: ' + JSON.stringify(to.name) + ', from: ' + JSON.stringify(from.name))

    // also see similar code in NavBar to collapse the hamburger for navigation to outside pages (target = _blank)
    try {
        if (!document) {
            // console.log('no document')
        }
        else {
            let burger = document.getElementById('navbarSupportedContent')
            if (!burger) {
                // console.log('no burger')
            }
            else {
                burger.classList.remove('show')
            }
            // console.log('burger classes: ' + JSON.stringify(burger.classList))
        }
    }
    catch (e) {
        // console.log('router.beforeEach error: ' + JSON.stringify(e))
    }

    store.commit('setPreviousRouteName', from.name)
})

export default router
